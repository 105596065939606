import React, { Fragment } from 'react'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
function NotFound() {
    return (
    <Fragment>
        <Stack sx={{            
            display: 'flex',
            justifyContent: 'center',
            py: 15,
            textAlign: 'center',
            color:'#f7f7f7'
        }}>
            <Typography variant="h1">404</Typography>
            <Typography variant="h6" sx={{color:'#d3d3d3'}}>The Resource Was Not Found</Typography>    
        </Stack>
    </Fragment>
  )
}

export default NotFound